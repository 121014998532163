export type FeatureFlag = {
	env: string | undefined;
	/**
	 * Sets the default will override the environment and api
	 */
	defaultValue?: boolean;
};

function flags<T extends { [key: string]: FeatureFlag }>(x: T) {
	return x;
}

export const featureFlags = flags({
	ENABLE_PLACE_OF_BIRTH_FIELD: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ENABLE_PLACE_OF_BIRTH_FIELD,
	},
	FORCE_ERROR: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_FORCE_ERROR,
	},
	ADD_BANK_ACCOUNT_NAME: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ADD_BANK_ACCOUNT_NAME,
	},
	DEFAULT_TO_SECONDARY_MARKET_OPPORTUNITES: {
		env: process.env
			.NEXT_PUBLIC_FEATURE_FLAG_DEFAULT_TO_SECONDARY_MARKET_OPPORTUNITES,
	},
	PAST_HOLDINGS_TAB: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_PAST_HOLDINGS_TAB,
	},
	MUTATION_PERMISSIONS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_MUTATION_PERMISSIONS,
	},
	SHOW_RETURNS_HISTORY: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_RETURNS_HISTORY,
	},
	ALL_DOCUMENTS_TABLE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALL_DOCUMENTS_TABLE,
	},
	ALL_TRANSACTIONS_TABLE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALL_TRANSACTIONS_TABLE,
	},
	PORTFOLIO_VALUE_GRAPH: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_PORTFOLIO_VALUE_GRAPH,
	},
	DISABLE_WALLETS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISABLE_WALLETS,
	},
	INVESTING_ENTITY_MANY_ACCOUNTS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_INVESTING_ENTITY_MANY_ACCOUNTS,
	},
	TRANSACTIONS_TABLE_EXPORT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS_TABLE_EXPORT,
	},
	TRANSACTIONS_TABLE_DATE_FILTER_ENABLED: {
		env: process.env
			.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS_TABLE_DATE_FILTER_ENABLED,
	},
	DOCUMENTS_TABLE_EXPORT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DOCUMENTS_TABLE_EXPORT,
	},
	DOCUMENTS_TABLE_DATE_FILTER_ENABLED: {
		env: process.env
			.NEXT_PUBLIC_FEATURE_FLAG_DOCUMENTS_TABLE_DATE_FILTER_ENABLED,
	},
	PORTFOLIO_PAGE_REVAMP: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_PORTFOLIO_PAGE_REVAMP,
	},
	HOLDING_PAGE_REVAMP: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_HOLDING_PAGE_REVAMP,
	},
	HIDE_REALESTATE_METRICS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_HIDE_REALESTATE_METRICS,
	},
	HIDE_PORTFOLIO_GRAPH: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_HIDE_PORTFOLIO_GRAPH,
	},
	RWT_RATE_2800: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_RWT_RATE_2800,
	},
	SIMPLIFIED_SECONDARY_MARKET: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_SIMPLIFIED_SECONDARY_MARKET,
	},
	FUND_DOCUMENTS_MAINTENANCE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_FUND_DOCUMENTS_MAINTENANCE,
	},
	HIDE_INVEST_TAB: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_HIDE_INVEST_TAB,
	},
	ALLOCATION_INVESTOR_PREFERENCES: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOCATION_INVESTOR_PREFERENCES,
	},
	ADMIN_REQUESTED_ACCOUNT_IDV: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ADMIN_REQUESTED_ACCOUNT_IDV,
	},
	UNIT_REDEMPTIONS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_REDEMPTIONS,
	},
	ALLOW_UNAUTHENTICATED_VIEWS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOW_UNAUTHENTICATED_VIEWS,
	},
	EXTEND_NATURE_AND_PURPOSE_ANSWERS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_EXTEND_NATURE_AND_PURPOSE_ANSWERS,
	},
	UNIT_CLASSES_V1: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_CLASSES_V1,
	},
	UNIT_CLASSES_V2: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_CLASSES_V2,
	},
	AU_IE_FIELDS_Q4_24: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_AU_IE_FIELDS_Q4_24,
	},
	DISTRIBUTION_REINVESTMENT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISTRIBUTION_REINVESTMENT,
	},
});

export type PossibleFeatureFlags = keyof typeof featureFlags;
